.badge {
  padding: 0.5em 1em;
  border-radius:0.3em;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.sale {
  background-color: #F89144;
  color: #F9E2D0;
}

.commission {
  background-color: #D5F0FF;
  color: #1F64E5;
}

.delivery {
  background-color: #FAEADE;
  color: #FF8B2D;
}

.registration {
  background-color: #1F64E5;
  color: white;
}
